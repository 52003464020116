* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
  border: medium none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: none !important;
}

img,
fieldset {
  border: 0 none;
}

a {
  border: none;
  outline: none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: none;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: red;
}

.clear {
  clear: both;
}

ul::after {
  clear: both;
  content: "";
  display: block;
}

ul {
  list-style: none;
  min-height: 150px;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 27px;
  color: #000;
  /* color:#e9ecef; */
  -webkit-font-smoothing: antialiased;
  font-family: "Wix Madefor Display", sans-serif;
}

.header-left img {
  height: 60px;
}

.upgrade-plan img {
  width: 37%;
  margin-bottom: 44px;
}

.modal-dialog .left-chat-boxes,
.modal-dialog .right-chat-boxes {
  margin: 0 0 15px 0;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 0 24px 10px;
  border: 1px solid #7269ef;
  border-radius: 22px;
}

.header-left span {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  margin-left: 10px;
}

.main-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activate-pro-faq {
  display: flex;
  align-items: center;
}

.header-top-left button {
  background: #fff0a3 !important;
  font-weight: 600;
  margin-right: 35px;
}

.header-top-left {
  cursor: pointer;
}

.header-top-left p {
  font-weight: 600;
}

.header-top-right p {
  font-weight: 600;
}

.header-top-right:before {
  content: "";
  position: absolute;
  background: #000;
  width: 2px;
  height: 21px;
  right: -20px;
  bottom: 3px;
}

.header-top-right {
  position: relative;
  margin-right: 35px;
}

.header-right {
  display: flex;
  align-items: center;
}

.email-dropdown {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.email-top-area h6 {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.email-top-area p {
  color: #5e5e5e;
  font-size: 13px;
  line-height: 15px;
}

.email-top-area {
  margin-right: 30px;
}

.dropdown-top .dropdown button {
  background: #7269ef;
  border: none;
}

.dropdown-top .dropdown-toggle::after {
  color: #000;
  position: absolute;
  right: -20px;
  top: 20px;
  font-size: 19px;
}

.dropdown-top .dropdown button {
  background: #7269ef !important;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 20px;
}

.dropdown-top .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background: #7269ef !important;
}

.dropdown-top .btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}

.header {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: rgb(255 255 255 / 79%);
  backdrop-filter: saturate(180%) blur(20px);
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none !important;
}

.left-chat-box {
  border-radius: 21px;
}

.chat-history-header span {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.add-chat-btn button {
  background: #7269ef !important;
  border: none !important;
  color: #fff !important;
  font-size: 17px;
  width: 100%;
}

.add-chat-btn {
  margin: 20px 0;
}

.chat-history-header {
  padding-top: 5px;
}

.chat-history-area h5 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.chat-history-area {
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 300px;
  overflow: auto;
  box-shadow: inset 0 0 10px #00000033;
}

.chat-history-area p {
  text-align: center;
  font-size: 14px;
  padding: 10px;
}

.chat-history-area span {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  border: 1px solid;
  padding: 10px;
  border-radius: 4px;
  padding: 2px 9px 2px 5px;
  width: 100%;
  margin: 2px 0px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}

.form-check .form-check-input {
  margin-top: 6px;
}

.chat-history-area h5 {
  font-size: 16px;
}

.chat-bookmark-area h5 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.chat-bookmark-area {
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 300px;
  min-height: 0px;
}

.chat-bookmark-area p {
  text-align: center;
  font-size: 14px;
  padding: 10px;
}

.chat-bookmark-area span {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  border: 1px solid;
  padding: 10px;
  border-radius: 4px;
  padding: 2px 9px 2px 5px;
  width: 100%;
  margin: 2px 0px;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}

.chat-bookmark-area h5 {
  font-size: 16px;
}

button.btn-disabled {
  background: #808080a8 !important;
}

.right-chat-box-dropdown .dropdown button {
  width: 70%;
  background: #7269ef;
  border: none !important;
  padding: 11px;
  border-radius: 0 12px;
  color: #fff;
}

.right-chat-box-dropdown .dropdown-menu.show {
  width: 100%;
  text-align: center;
  border: none;
}

.prompt-box .dropdown-item p {
  background: #ededed;
  padding: 7px 22px;
  margin: 6px 0;
  border-radius: 12px;
  text-align: left;
  white-space: normal;
}

.dropdown-input-area {
  border: 2px dashed rgb(204, 204, 204);
  padding: 15px 35px;
}

.uplaod-document-area {
  position: relative;
  border: 1px dashed #00000069 !important;
  padding: 17px;
  border-radius: 12px;
  margin-bottom: 11px;
}

.uplaod-document-area label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}

.uplaod-document-area input {
  width: 264px;
  position: absolute;
  left: -37px;
  top: -11px;
  opacity: 0;
  height: 65px;
}

.uplaod-document-area .dropdown-item.active,
.dropdown-item:active {
  background-color: unset !important;
  color: #000 !important;
}

.uplaod-document-area .dropdown-item:focus,
.dropdown-item:hover {
  background: none !important;
}

.right-chat-box-dropdown {
  margin: 10px 0 10px;
  text-align: center;
}

i.fa.fa-times.top-cross-icon {
  justify-content: end;
  display: flex;
}

.right.chat.box {
  border-radius: 24px;
  height: 76vh;
  max-height: 719px;
  overflow: auto;
}

/* vishal */
.mid-chat-box {
  border-radius: 21px;
  border: 1px solid #7269ef;
  max-height: 100%;
}

.chatbox-header {
  padding: 21px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #7269ef;
}

.chatbox-footer {
  border-top: 1px solid #7269ef;
  position: relative;
  padding-top: 44px;
}

.chatbox-body {
  padding: 23px;
  height: 480px;
  overflow: auto;
  min-height: 480px;
}

.left-chat-box {
  border-radius: 0 16px 16px 16px;
  white-space: pre-line;
  margin-top: 14px;
}

#chat-response-id {
  white-space: pre-line;
  margin-top: 14px;
}

.left-chat-boxes {
  max-height: 796px;
  overflow: auto;
}

.left-chat-boxes,
.right-chat-boxes {
  /* overflow: auto !important; */
  /* height: 800px !important; */
  margin: 0 0 15px 0;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 24px 0 10px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  max-height: 100%;
}

.left-chat-box p {
  background: #fff;
  padding: 12px;
  border-radius: 0 15px 15px 15px;
  display: inline-block;
  margin: 0 0 20px 0;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  /* cursor: pointer; */
}

.right-chat-box p {
  background: #fff0a3;
  padding: 12px;
  border-radius: 15px 15px 0 15px;
  display: inline-block;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.privacy-box a {
  color: #474646;
  text-decoration: none;
  margin: 0 12px 0 0;
  text-align: right;
  display: inline-block;
}

.right-chat-box-dropdown .dropdown-menu.show {
  width: 100%;
  text-align: center;
  transform: unset !important;
  border: none;
  position: relative !important;
  background: transparent;
  box-shadow: unset;
}

.prompt:hover,
.btn:focus-visible {
  background: #fff0a3 !important;
  border: unset !important;
  color: #000 !important;
}

.privacy-box {
  text-align: right;
}

.social-handle a {
  margin: 0 17px 0 0;
}

.right-chat-box {
  text-align: right;
  margin: 12px 0 0 0;
}
.uploaded-pdf-area.pdf-upload-text {
  background: #d8d5fb;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 12px 12px 0 12px;
  padding: 6px 13px;
  border-radius: 9px;
  color: #7269ef;
  font-weight: 600;
  width: fit-content;
}
.uploaded-pdf-area.pdf-upload-text i {
  margin-left: 9px;
  cursor: pointer;
}
.mid-chat-box form {
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  margin: 0 20px 12px;
  padding: 16px;
  position: relative;
  border: 1px solid #00000021;
}

.inner-content p {
  white-space: normal;
  font-size: 14px;
  margin: 0;
  box-shadow: unset;
  padding: 0;
}

.mid-chat-box input,
.mid-chat-box textarea {
  /* overflow: hidden; */
  resize: none;
}

button.rec-submit.recording-submit-btn {
  margin-left: auto;
  background: #696969eb;
  border: none;
}

.read-more.btn.btn-primary {
  display: block;
  margin-bottom: 0 !important;
  padding: 7px 15px !important;
  width: auto;
  font-size: 15px;
  border-radius: 10px;
  text-align: center;
  margin: 5px auto;
  background: #fff0a3;
  color: #000;
}

.inner-chatbot {
  padding: 110px 0 50px;
  min-height: calc(100vh - 122px);
}

.label-mb-3 {
  margin-right: 191px;
  color: #000;
}

footer {
  padding: 15px;
  border-top: 1px solid #7269ef2e;
}

.attach_file,
.attach_file-light {
  background-image: url("../assests/images/file.svg");
}

.attach_file {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  background-color: initial;
  background-position: 50%;
  background-size: cover;
  border: none;
  float: right;
  height: 30px;
  margin-right: 10px;
  -webkit-user-select: none;
  user-select: none;
  width: 30px;
}

.mid-chat-box input {
  display: none;
}

textarea {
  -ms-overflow-style: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  max-height: 25vh;
  overflow-y: visible;
  padding: 0 10px 0 0;
  scrollbar-width: none;
  width: 100%;
}

.submit,
textarea {
  background-color: initial;
  border: none;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

a.menu-content-area.dropdown-item {
  height: 250px;
  overflow: auto;
  min-height: 250px;
}

.cancel-area-box {
  padding: 0 !important;
  border: none !important;
  box-shadow: unset !important;
}

.cancel-area-box button {
  width: 50% !important;
}

.cancel-area-box .modal-body {
  padding-bottom: 0 !important;
}

.cancel-plan-box-area .modal-content {
  width: 80%;
  margin: 0 auto;
}

.cancel-area-box h5 {
  font-size: 20px !important;
}

/* .submit,
.submit-light {
  background-image: url("../assests/images/save.svg");
} */
.submit {
  -webkit-touch-callout: none;
  margin-left: 8px;
  margin-top: 0;
  -webkit-user-select: none;
  font-size: 20px;
  border: 1px solid #7269ef69;
  padding: 12px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #7269ef;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-chat-box.under-file-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
button.send-btn-disabled {
  color: #808080a6;
  border: 1px solid #808080a6;
}
.file-upload-text {
  padding: 12px;
  background: #d8d5fb !important;
  display: block;
  box-shadow: unset;
  width: fit-content;
  text-align: right;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  padding: 6px 12px !important;
  border-radius: 8px !important;
  color: #7269ef;
  font-weight: 600;
}
.prompt {
  position: absolute;
  right: 50px;
  top: 8px;
  background: #fff0a3;
  border: none;
  color: #000;
  border-radius: 10px 10px 0 0;
}

.paragraph-txt {
  font-size: 14px;
  margin-top: 13px;
}

.login-area {
  height: 100vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.privacy-area {
  min-height: 600px;
}

.login-right {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 30px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  min-width: 610px;
  text-align: center;
}

.login-right h6 {
  font-size: 26px;
  color: #000;
  font-weight: 500;
}

/* .google-login-btn {
  margin: 35px 10px 17px;
} */
.google-login-btn .form-control {
  padding: 14px;
  background: transparent;
  border: 1px solid;
}

.google-login-btn button {
  background: #fff0a3 !important;
  width: 50%;
  padding: 10px 0;
  font-size: 17px;
  font-weight: 600;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  border: none !important;
}

.login-right p {
  color: #5d5d5d;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 51px 30px;
}

.login-right input::placeholder {
  font-size: 14px;
}

.login-right input {
  border: 1px solid #ccc !important;
  border-radius: 15px;
}

.login-bottom-btn button {
  width: 100%;
  background: #7269ef !important;
  border: none !important;
  padding: 11px;
  color: #fff !important;
  border-radius: 0 12px;
  font-size: 18px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.login-bottom-btn {
  margin-top: 28px;
}

.on-boarding-login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-left {
  background: linear-gradient(239deg,
      rgba(255, 240, 160, 1) 0%,
      rgba(113, 104, 236, 1) 49%,
      rgba(59, 46, 228, 1) 100%);
  height: 100vh;
}

strong {
  display: block;
}

.login-left img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.dropdown-toggle.show::after {
  border-top: 0;
  vertical-align: middle;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle::after {
  vertical-align: middle !important;
}

.privacy-list li {
  list-style: circle;
  margin-left: 21px;
}

.privacy-box .privacy-content:hover {
  color: #7269ef;
}

.privacy-list {
  min-height: unset;
  margin-bottom: 17px;
}

.privacy-content {
  /* cursor: pointer; */
  margin: 0 18px;
}

.privacy-content {
  margin-top: 120px;
  margin-bottom: 49px;
}

.privacy-content h2 {
  margin-bottom: 12px;
}

.signup-error input {
  border: 1px solid red !important;
}

.signup-error label span {
  color: red !important;
}

.signup-error label span a {
  color: red !important;
}

.terms-label label {
  float: left;
}

.upgrade-to-pro-style {
  cursor: pointer;
  text-align: center;
  padding: 12px;
  border: 1px solid #7269ef;
  border-radius: 0 15px 0 15px;
  margin: 12px 12px;
  width: 22%;
  color: #000;
  margin: 20px auto;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

textarea#input_focused {
  margin-top: 0;
}

.upgrade-to-plan-style {
  cursor: pointer;
  text-align: center;
  padding: 4px;
  border: 1px solid #7269ef;
  border-radius: 0 15px 0 15px;
  margin: 12px 12px;
  width: 50%;
  color: #000;
  margin: 20px auto;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
  white-space: nowrap;
}

.google-logo-style {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.bookmark-img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.history-obj-active {
  border: 1px solid #7269ef !important;
  background: #d8d5fb !important;
}

.modal {
  background: #000000b5;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* display: flex !important; */
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 22px;
  border-radius: 32px;
  /* backdrop-filter: blur(5px); */
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #000;
  background-color: #fff0a3;
  border-color: #fff0a3;
  box-shadow: unset;
}

.dropdown-menu.show {
  border: none;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  right: -30px !important;
}

.chatbox-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.chatbox-body::-webkit-scrollbar {
  width: 2px;
  background-color: #7269ef;
}

.chatbox-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #7269ef;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #7269ef;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #7269ef;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.chat-bookmark-area::-webkit-scrollbar {
  width: 2px;
  background-color: #7269ef;
}

.chat-bookmark-area::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #7269ef;
}


.microphone-section-area .microphone-icon-container.submit {
  margin: 0 auto 4px;
  cursor: pointer;
}

.microphone-section-area {
  width: 65px;
}


.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.microphone-status {
  font-size: 10px;
  line-height: 10px;
  text-align: center;
}

.send-record-btn {
  display: flex;
}

.typing-loader {
  width: 6px;
  height: 6px;
  -webkit-animation: line 1s linear infinite alternate;
  -moz-animation: line 1s linear infinite alternate;
  animation: line 1s linear infinite alternate;
}

@-webkit-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@-moz-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

/* .hero-banner {
  background-image: url("../assests/images/chatbot-hero-bg.webp");
  margin: 0 42px;
  height: 100vh;
  border-radius: 0 0 102px 102px;
} */
.atory-img {
  background-image: url("../assests/images/chatbot-feature-box-1.webp");
  width: 100%;
  padding: 82px;
  border-radius: 31px;
}

.premium-plans {
  color: #000;
  font-size: 18px;
}

.hero-content {
  /* padding: 0 130px 0 2px; */
  color: #000 !important;
}

.hero-content p {
  width: fit-content;
  margin: 0 auto;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  font-weight: normal;
}

.hero-content h1 {
  font-size: 70px;
  font-weight: normal;
  color: #fff;
  font-weight: normal;
  margin-bottom: 0;
  display: inline-block;
  border-radius: 5px;
  /* background: linear-gradient(180deg, rgba(255, 240, 163, 0.34902) 0%, rgba(255, 255, 255, 0) 100%); */
  padding: 23px;
}

.hero-top {
  padding-top: 92px;
  position: relative;
}

.chat-history-left span {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  border: 1px solid;
  padding: 10px;
  border-radius: 4px;
  padding: 2px 9px 2px 5px;
  width: 100%;
  margin: 2px 0px;
  background-color: #fff;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-history-area.chat-history-left i.fa.fa-trash-o {
  color: red;
}

.try-ti-buton {
  padding: 13px;
  background: #7269ef !important;
  color: #000;
  font-size: 15px;
  display: inline-block;
  margin: 0 0 0 0;
  padding: 14px 15px;
  border-radius: 0 22px;
}

.animated-btn {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;

}

@-webkit-keyframes glowing {
  0% {
    background-color: #7269ef;
    -webkit-box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    -webkit-box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    -webkit-box-shadow: 0 0 3px #7269ef;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #7269ef;
    -moz-box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    -moz-box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    -moz-box-shadow: 0 0 3px #7269ef;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }
}

@keyframes glowing {
  0% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }

  50% {
    background-color: #7269ef;
    box-shadow: 0 0 40px #7269ef;
  }

  100% {
    background-color: #7269ef;
    box-shadow: 0 0 3px #7269ef;
  }
}

.common-padding {
  padding: 90px 0;
}

.sub-heading {
  font-size: 50px;
  color: #000;
  font-weight: 800;
  text-align: center;
  margin-bottom: 25px;
}

.feature-inner {
  display: flex;
  position: relative;
}

.note {
  font-size: 13px;
  margin-bottom: 28px;
  font-style: italic;
  font-weight: 700;
}

input#exampleForm\.ControlInput1 {
  border: 1px solid #00000047;
}

.feature-inner span {
  font-size: 242px;
  color: #7269ef;
  position: absolute;
  font-weight: 700;
  display: inline-block;
  padding: 0 22px 0 0;
  left: 26%;
  opacity: 0.2;
  top: 1px;
}

.feature-inner h4 {
  color: #000;
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: uppercase;
}

.feature-box img {
  width: 60%;
  display: inherit;
  margin: 0 auto;
}

.feature-inner div {
  padding: 0 25px;
  margin-bottom: 39px;
}

.feature-box.main-feature {
  background-image: url("../assests/images/bg-card.png");
  padding: 42px;
  background-repeat: no-repeat;
  height: 100%;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  background-size: cover;
  border-radius: 43px;
  color: #fff;
}

.feature-box {
  background: #fff;
  padding: 42px;
  background-repeat: no-repeat;
  height: 100%;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
  background-size: cover;
  border-radius: 43px;
  color: #000;
  border: 1px solid #0000004a;
}

.pricing-area {
  /* min-height: calc(100vh - 122px); */
  padding: 109px 0;
}

.pricing-top-heading-area {
  text-align: center;
  margin-bottom: 45px;
}

.card-detail {
  min-height: 94vh;
}

.click-here-anchor {
  text-decoration: underline;
  color: #7269ef;
  cursor: pointer;
}

/* .card1-dummy {
  min-width: 370px;
} */
.dummy-card-area .modal-dialog {
  width: 100%;
  margin: 0 16px;
}

.dummy-card-area .modal-body {
  padding: 0;
}

.pricing-area {
  background-image: url("../assests/images/pricing-bg.png");
  background-repeat: no-repeat;
  background-position: left;
}

.pricing-content-area {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 30px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  height: 100%;
  /* cursor: pointer; */
  position: relative;
}

.form-check-input {
  cursor: pointer;
}

.pricing-top-heading-area p {
  color: #000;
}

.pricing-top-heading-area h4 {
  color: #000;
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 15px;
}

.pricing-content-area:hover img {
  filter: invert(1);
}

.pricing-content-area:hover {
  background: #7269ef;
  color: #fff;
}

.pricing-content-area:hover h5 {
  color: #fff;
}

.pricing-content-area:hover button {
  background: #fff0a3 !important;
  color: #000;
}

.pricing-content-area h5 {
  color: #7269ef;
  margin: 22px 0;
  font-size: 33px;
  font-weight: 800;
}

.pricing-content-area button i {
  margin-left: 5px;
}

.pricing-top p {
  color: #000;
}

.pricing-content-area:hover p {
  color: #fff;
}

.pricing-content-area:hover ul li {
  color: #ffff;
}

.pricing-content-area ul li {
  padding: 5px 0 5px 0;
  font-weight: 500;
  color: #000;
  font-size: 16px;
}

.pricing-content-area button {
  background: #7269ef !important;
  border: none;
  margin-top: 30px;
  padding: 10px 18px;
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  color: #fff;
}

.selected-plan-box {
  background: #7269ef;
}

.selected-plan-box .pricing-top img {
  filter: invert(1);
}

.selected-plan-box .pricing-top p {
  color: #fff;
}

.selected-plan-box h5 {
  color: #ffffff !important;
}

.selected-plan-box ul li {
  color: #fff;
}

.selected-plan-box ul li img {
  filter: invert(1);
}

.selected-plan-box button {
  background: #fff0a3 !important;
  color: #000;
  border-radius: 5px;
  /* pointer-events: none; */
}

.curretn-plan-none {
  pointer-events: none;
}

.pdf-message-style button {
  display: inline-table;
  margin-left: 4px;
}

.cursor-default-important {
  cursor: unset !important;
}

#cursor-pointer-important {
  cursor: pointer !important;
}

.cursor-default-important button {
  cursor: unset !important;
}

.payment-detail-area {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
  padding: 20px 30px;
  border: 1px solid #7269ef;
  border-radius: 22px;
  height: 100%;
  background-color: #fff;
  /* cursor: pointer; */
}

.payment-add-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.payment-add-card h4 {
  color: #000;
  font-weight: 600;
}

.payment-detail-area form {
  border-bottom: 1px solid #8080803d;
  padding-bottom: 30px;
}

.add-card button {
  background: #fff0a3 !important;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding: 7px 13px;
  border: none !important;
  white-space: nowrap;
}

.add-card button i {
  margin-right: 6px;
}

.card-detail-box {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 5px;
  align-items: center;
}

.cvv-txt {
  border: 1px solid;
  padding: 2px 17px;
  border-radius: 32px;
  display: inline-block;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.saved-view-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-detail-box {
  display: flex;
  justify-content: space-between;
}

.card1 {
  background: radial-gradient(at top left, #ffffffa1 0%, #7269ef 47%);
  padding: 30px 16px;
  border-radius: 20px;
  color: #fff;
  width: 80%;
  margin: 12px auto;
  position: relative;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.card2 {
  background: radial-gradient(at top left, #86b8ff 0%, #584fd8 47%);
}

.saved-view-card p {
  color: #7269ef;
  font-weight: 600;
  text-decoration: underline;
}

.saved-view-card h6 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.payment-detail-area .form-label {
  color: #000;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 500;
}

.form-control:focus {
  /* color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0; */
  box-shadow: unset !important;
}

.payment-detail-area .form-control::placeholder {
  font-size: 14px;
}

.selected-plan-box {
  height: fit-content;
}

button.pay-now-btn {
  background: #7269ef !important;
  border: none;
  margin-top: 15px;
  padding: 9px 40px;
  font-size: 16px;
}

.payment-detail-area .form-control {
  border: none;
  background: #d4d4d457;
  height: 48px;
}

.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement {
  max-width: 100%;
}

.payment-detail-area form label {
  width: 100%;
}

.form-detail-confirm-btn button {
  background: #7169ef;
  margin-top: 20px;
  color: #fff;
  padding: 7px 17px;
  font-weight: 600;
  border-radius: 5px;
}

.cool-features-area {
  padding: 0 0 60px 0;
}

.cool-features-area .accordion-item {
  margin-bottom: 25px;
}

.advantage {
  background: linear-gradient(to right, #000000 0, #7269ef 50%, #7269ef 100%);
  padding: 100px 0;
}

.NFTICALLYAdvantageContent .text-light {
  color: #fff !important;
  font-size: 57px;
  font-weight: 800;
  margin-bottom: 15px;
}

.NFTICALLYAdvantageContent p {
  color: #fff;
  font-weight: 300;
}

.card-header {
  padding: 25px;
}

.card {
  border: none;
  border-radius: unset !important;
  border-bottom: 1px solid #80808036;
}

.awesome-inner-box span {
  font-size: 42px;
  margin-bottom: 30px !important;
  display: block !important;
}

.card .dropdown-top .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background: #7269ef !important;
}

.card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed {
  font-size: 19px;
  font-weight: 600;
  text-decoration: none;
  text-transform: unset !important;
  width: 100%;
  text-align: left;
}

button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link {
  text-transform: unset !important;
  text-decoration: none;
  font-size: 19px;
  color: #000 !important;
  font-weight: 600;
  width: 100%;
  text-align: left;
}

.text-left {
  text-align: left;
}

.attorney-section ul li {
  margin: 16px 0 20px 0;
  line-height: 22px;
  list-style: unset !important;
}

.awesome-section.common-padding {
  background-image: url("../assests/images/atory-banner\ \(1\).jpg");
  color: #fff;
}

/* .hero-banner {
  background: url("../assests/images/banner.jpg") no-repeat;
  padding: 22px 0 54px 0;
  background-size: cover;
  background-position: center top;
} */
.hero-banner {
  position: relative;
  padding-top: 80px;
}

.advantage {
  background: url("../assests/images/feature.jpg") no-repeat;
  padding: 74px 0 74px 0;
  background-size: cover;
  background-position: center top;
}

.overlay-layer {
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  background: linear-gradient(349deg, rgb(40 8 92 / 28%) 0%, rgb(68 56 230) 100%);
  /* opacity: 0.8; */
  height: 100%;
}

.hero-content {
  color: #fff !important;
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 31px;
  left: 50%;
  border-radius: 12px;
  /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); */
  transform: translate(-50%, -50%);
  /* box-shadow: 0px 4px 20px rgb(0 0 0 / 14%); */
}

.awesome-inner-box {
  background: #000000ab;
  padding: 35px;
  border-radius: 12px;
  border: 1px solid #fff;
  height: 100%;
}

.awesome-inner-box h4 {
  margin-bottom: 15px;
}

.advantage2 {
  background: #fff;
}

.advantage2 h2.text-light {
  color: #000 !important;
}

.advantage2 p {
  color: #000;
}

.hero-content span {
  color: #fff0a3;
  font-size: 70px;
  font-weight: 800;
}

.main-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000e6;
  z-index: 1056;
  top: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.plan-disabled {
  background-color: lightgray;
  border: none;
  cursor: unset;
}

.plan-disabled button {
  cursor: default !important;
}

.plan-disabled:hover {
  background: lightgray;
}

.plan-disabled:hover img {
  filter: unset;
}

.plan-disabled:hover h5 {
  color: #7269ef;
}

.plan-disabled:hover ul li {
  color: #000;
}

.plan-disabled:hover button {
  background: #7269ef !important;
  color: #fff;
}

.saved-cards-area {
  height: 400px;
  overflow: auto;
}

.saved-cards-area::-webkit-scrollbar {
  width: 2px;
}

.saved-cards-area::-webkit-scrollbar-track {
  background-color: #7269ef33;
}

.saved-cards-area::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #7269ef;
}

.selected-card i {
  font-size: 14px;
}

.selected-card {
  position: absolute;
  right: -5px;
  top: -6px;
  background: darkgreen;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.sub-para {
  font-size: 10px;
  text-align: center;
  margin: 0 0 14px 0;
}

.hero-banner video {
  width: 100%;
  border-radius: 0 0 102px 102px;
  height: 812px;
  /* filter: blur(2px);
    -webkit-filter: blur(2px); */
}

/* .hero-outer {
    z-index: 999;
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */
.modal-dialog-centered .try-ti-buton {
  padding: 6px 20px;
  margin: 0;
  color: #fff;
}

.modal-dialog-centered .sub-para {
  color: #7269ef;
  background-color: transparent;
  box-shadow: unset;
  margin: 0;
}

.modal-title.h4 {
  font-size: 26px;
  font-weight: 800;
  color: #000;
}

.login-form-box {
  padding: 41px;
  border-radius: 19px;
  height: 100%;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  background: #fff;
  border: 1px solid #00000026;
}

.modal-header {
  border: 0;
}

.btn-close:focus {
  box-shadow: unset !important;
  opacity: 1;
}

.btn-close:after {
  content: "Close";
  font-size: 17px;
  color: #000 !important;
  font-weight: 700;
  opacity: 1 !important;
  position: absolute;
  top: 28px;
  right: 31px;
}

.btn-close {
  background: unset !important;
}

.form-check-input:checked {
  background-color: #7269ef !important;
  border-color: #7269ef !important;
}

.form-check-input:focus {
  box-shadow: unset !important;
}

.login-form-box h5 {
  color: #000;
  font-weight: 700;
  font-size: 25px;
}

.login-form-box span {
  color: #000;
}

.login-form-box a {
  color: #7269ef;
  text-decoration: none;
  font-weight: 600;
}

/* .modal.show .modal-dialog {
    min-width: 912px;
} */
.hero-gif img {
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius: 52px;
  border: 10px solid #7269ef6e;
  object-fit: cover;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

.assests {
  position: absolute;
  top: 50%;
  border-radius: 42px 0 42px 42px;
  left: 21%;
  width: 173px;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

div:where(.swal2-icon).swal2-success {
  margin: 0 auto !important;
}

div:where(.swal2-container).swal2-center>.swal2-popup {
  padding: 30px !important;
}

.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  border-radius: 42px !important;
  background: #fff !important;
}

.swal2-container.swal2-top,
.swal2-container.swal2-center,
.swal2-container.swal2-bottom {
  grid-template-columns: auto minmax(0, 1fr) auto;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgb(0 0 0 / 86%);
  backdrop-filter: blur(5px);
}

.swal2-styled.swal2-confirm {
  padding: 9px 30px !important;
  border-radius: 0 17px !important;
  box-shadow: 0px 20px 60px 0px rgba(10.207540760869565, 14.185793738554464, 20.999999999999996, 0.1);
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #7066e0;
  color: #fff;
  font-size: 1em;
  padding: 9px 30px;
  border-radius: 0 17px;
}

.current-plan {
  position: absolute;
  top: -55px;
  left: 49%;
  background: #fff !important;
  color: #7269ef;
  border: 1px solid #7269ef;
  border-radius: 42px;
  color: #fff;
}

#swal2-title {
  color: #000 !important;
}

.dropdown-top.mobile-tab {
  display: none;
}

.pricing-content-area .terms-label label {
  color: #fff;
}

.pricing-content-area a {
  color: #fff0a3;
  text-decoration: none;
}

.pricing-content-area .form-check-input:checked {
  background-color: transparent !important;
  border: 1px solid #fff !important;
}

/* .capabilities-section h2:after {
  content: "\f078";
  position: absolute;
  font-family: "FontAwesome";
  font-size: 13px;
  right: 37px;
  pointer-events: none;
} */
.capabilities-section h2 {
  display: flex;
  align-items: center;
}

.privacy-area {
  background-image: url("../assests/images/pricing-bg.png");
  background-repeat: no-repeat;
  background-position: left;
}

.inner-content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error-area h1 {
  width: auto;
  display: inline-block;
  font-size: 7em;
  line-height: 100%;
  letter-spacing: 0.2em;
  text-shadow: 8px -3px 0 rgba(240, 85, 85, 0.6),
    -10px 5px 0 rgba(72, 104, 244, 0.6), 9px 7px 0 rgba(255, 219, 89, 0.6);
  transition: all 0.2s ease;
}

.error-area {
  text-align: center;
  height: 100vh;
}

.error-area h1:hover {
  text-shadow: 4px -1px 0 rgba(240, 85, 85, 0.6),
    -5px 2px 0 rgba(72, 104, 244, 0.6), 6px 3px 0 rgba(255, 219, 89, 0.6);
  transition: all 0.2s ease;
}

.error-area h3 {
  font-size: 2.5em;
}

.border-red {
  border-radius: 5px;
  border: 1px solid red !important;
}

.error-area span {
  cursor: pointer;
  padding: 15px 15px;
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  background-color: #7269ef;
  box-shadow: -7px 7px 0 rgba(72, 104, 244, 0.7),
    -12px 12px 0 rgba(255, 219, 89, 0.8);
  transition: all 0.2s ease;
}

.error-area a:hover {
  box-shadow: -3px 3px 0 rgba(72, 104, 244, 0.7),
    -6px 6px 0 rgba(255, 219, 89, 0.8);
  transition: all 0.2s ease;
}

.modal-area-box .modal-dialog {
  width: 100%;
}

.modal-area-box label.form-label {
  display: flex;
  justify-content: left;
  font-weight: 600;
  margin-bottom: 1px;
}

.modal-area-box textarea#formFile {
  margin-top: 0;
}

.phone-input input {
  width: 100% !important;
}

.overflow {
  /* max-height: 760px; */
  /* overflow: auto; */
  padding: 0 20px;
}

section.attorney-section {
  padding-bottom: 0;
}

button.try-ti-buton:disabled {
  background: darkgray !important;
  color: #fff;
}

button.get-in-touch {
  background: #fff0a3 !important;
  border: none;
  color: #000 !important;
}

.call-to-action-right button {
  margin-top: 0;
}

.call-to-action-right {
  justify-content: end;
  display: flex;
}

.call-to-action-left h4 {
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}

.call-to-action-left h4 span {
  color: #fff0a3;
}

.top-call-to-action .container {
  margin-top: 0;
  margin-bottom: 70px;
}

section.features-section {
  padding-bottom: 18px;
}

.call-to-action-left p {
  color: #fff;
  margin-top: 10px;
  font-size: 19px;
}

.selected-card.delete-card {
  right: 31px;
  background: #f00;
}

.call-to-action .container {
  background: linear-gradient(268.68deg,
      #b0e8f7 25.8%,
      rgb(114 105 239) 90.26%);
  padding: 50px;
  border-radius: 20px;
  margin-top: 70px;
}

.pricing-contact-btn {
  text-align: center;
}

.chat-history-left {
  box-shadow: none;
}

.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  opacity: 0.5;
  border: 3px solid #09acfd;
  -webkit-animation: loader 1s ease-in-out infinite alternate;
  animation: loader 1s ease-in-out infinite alternate;
}

.loader:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border: 3px solid #09acfd;
}

.loader:after {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 3px solid #09acfd;
}

@keyframes loader {
  from {
    transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }

  to {
    transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg) scale(1, 1);
    border-radius: 0px;
  }

  to {
    -webkit-transform: rotate(360deg) scale(0, 0);
    border-radius: 50px;
  }
}

.loader3 {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.loader3 span {
  position: absolute;
  display: inline-block;
  width: 80px;
  height: 80px;
  top: -40%;
  left: -30%;
  border-radius: 100%;
  background: rgb(255 255 255);
  animation: loader3 1.5s linear infinite;
  transform: translate(-40%, -30%);
}

.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

.legal-research-area {
  height: 300px;
  min-height: 300px;
  overflow: auto;
}

.feature-box.main-feature span.text-light {
  color: #9289ff !important;
}

.chat-popup-area label.form-label {
  text-align: left;
  display: flex;
  margin-bottom: 0;
  color: #000;
  font-weight: 500;
  font-size: 15px;
}

.chat-popup-area input {
  margin-bottom: 10px;
}

.create-agreement-form-area .dropdown-menu.show h6 {
  padding: 20px 0 15px;
  font-weight: 700;
}

.home-centered-btn {
  background: #fff0a3 !important;
  color: #000;
  font-weight: 500;
}

p.active {
  color: #7269ef;
}

ul li img {
  width: 14px;
  margin-right: 4px;
}

@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

.card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed:after {
  content: "\f078";
  position: absolute;
  font-family: "FontAwesome";
  font-size: 13px;
  right: 37px;
  pointer-events: none;
}

.card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link:after {
  content: "\f077";
  position: absolute;
  font-family: "FontAwesome";
  font-size: 13px;
  right: 37px;
  pointer-events: none;
}

.pagination1 ul.pagination li {
  width: 38px;
  height: 35px;
  border: 1px solid #00000040;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px 2px 0;
  border-radius: 4px;
}

.pagination1 ul.pagination li a {
  text-decoration: none;
  color: #808080b0;
  font-weight: 700;
  font-size: 14px;
}

.pagination1 ul.pagination li.active {
  border: 1px solid #7269ef !important;
}

.pagination1 ul.pagination li.active a {
  color: #7269ef !important;
}

.prompt-library-dropdown-area .dropdown button {
  width: 100%;
}

button.clear-all-btn {
  display: flex;
  color: #7269ef;
  font-weight: 600;
  text-decoration: none;
  background-color: transparent;
  align-items: center;
  /* border: 1px solid #7269ef;
  padding: 0 6px; */
  border-radius: 4px;
  font-size: 14px;
  margin: 4px 0 6px auto;
  height: 25px;
}

.swal2-icon.swal2-info.swal2-icon-show {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dummy-card-area .modal-dialog {
  margin: 0 auto;
}


.stop-generating {
  position: relative;
  top: -50px;
  text-align: center;
}

.stop-generating button {
  background: #d2d2d2;
  padding: 4px 10px;
  border-radius: 10px;
  color: #5a5757;
}

.stop-generating button i {
  padding-right: 5px;
}

.modal-dialog-centered .sub-para.upload-document {
  color: #000000;
  background-color: #a49fe552;
  box-shadow: unset;
  margin: 0;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: unset;
  border-radius: 32px;
  margin: 7px 2px;
}

.fa.fa-close {
  color: red;
  cursor: pointer;
}

.cancel-area-box textarea::placeholder {
  font-size: 14px;
}

.cancel-area-box textarea {
  border: 1px solid #00000036;
  border-radius: 10px;
  padding: 8px 12px 12px 12px;
  height: 80px;
  min-height: 80px;
}

button.clear-all-btn img {
  width: 20px;
}

i.fa.fa-stop {
  font-size: 17px;
}

.other-feature-dropdown .dropdown button {
  width: 100%;
}

.top-spacing {
  margin-top: 0;
}

.top-spacing p {
  margin-bottom: 15px;
}

i.fa.fa-sign-out {
  transform: rotate(180deg);
  font-size: 18px;
  color: #000;
  margin-right: 7px;
}

.dropdown-top a.dropdown-item {
  font-size: 17px;
  font-weight: 500;
  color: #000;
}

.chatbox-footer form textarea {
  margin-top: 0;
}

video.updated-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* updated css home page */
video.updated-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.updated-content h5 {
  color: #fff;
  font-size: 37px;
}

.updated-content h1 {
  font-size: 62px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 14px;
}

.get-your-btn.btn.btn-primary,
.get-your-btn.btn.btn-primary:focus-visible {
  background: #7269ef;
  border: none;
  padding: 21px 41px;
  border-radius: 0;
  margin-right: 12px;
  white-space: nowrap;
  color: #fff;
}

.inner-blog-section .swiper-pagination-bullet-active {
  background: #7269ef;
}

.get-your-btn.explore-style.btn.btn-primary,
.get-your-btn.explore-style.btn.btn-primary:focus-visible {
  background: #000 !important;
  color: #fff !important;
}

.powerfull-section {
  padding: 132px 0 0 0;
  position: relative;
}

.updated-content {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 9;
}

.powerfull-content p {
  margin-bottom: 32px;
}

.powerfull-content h2 {
  font-size: 49px;
  margin-bottom: 15px;
  font-weight: 800;
  letter-spacing: -3px;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.inner-blog-section .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.inner-blog-section .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: unset;
}

.inner-blog-section .swiper-slide img {
  display: block;
  width: 100%;
}

.blog-card {
  padding: 35px 15px;
  border: 1px solid #0000003d;
  border-radius: 22px 22px 0 0;
  background: #fff;
}

.blog-card img {
  width: 100%;
  height: 192px;
  object-fit: cover;
  position: relative;
  top: -50px;
  border-radius: 10px;
}

.blog-text {
  background: #7269ef42;
  color: #000;
  padding: 9px 12px;
  border-radius: 9px;
  font-size: 13px;
}

.blog-card h2 {
  font-size: 23px;
  margin: 0 0 32px 0;
  font-weight: 600;
}

.slider-blog-section {
  padding: 70px 0 72px 0;
  background: #7269ef21;
}

.slider-blog-section .subheading-text {
  animation: typing 4s steps(38) 1s 1 normal both;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
}

.subheading-text {
  font-size: 41px;
  font-weight: 200;
  letter-spacing: -1px;
}

.build-section {
  padding: 82px 0;
}

.build-section .nav.nav-tabs {
  flex-direction: column;
  line-height: 42px;
  margin-top: 42px
}

.build-section .nav-link {
  border: none;
  padding: 0;
  margin-top: 12px;
}

.build-section .accordion-item {
  border: none !important;
  border-radius: unset !important;
  box-shadow: unset;
}

.build-section .accordion-button {
  background: #cec9ff;
  padding: 30px;
  border-radius: 17px !important;
  box-shadow: unset !important;
  font-weight: 600;
  font-size: 21px;
}

.accordion-collapse.collapse {
  background: #fff;
  border: 1px solid #9999993d;
  border-radius: 22px !important;
}

.text-right {
  text-align: right;
}

.margin-top60 {
  margin-top: 130px;
}

.talk-section {
  padding: 140px 0;
}

.udated-hero-section {
  position: relative;
}

.udated-hero-section:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 99%;
  background: #0000009e;
  z-index: 0;
  top: 0;
  left: 0;
}

video.content-video {
  width: 108%;
  position: absolute;
  left: -90px;
  top: 0;
  border-radius: 32px;
}

.powerfull-content-under-img:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 380px;
  background: #7269ef52;
  top: -30px;
  right: 8px;
  border-radius: 52px;
  z-index: -1;
}

.responsibilty-img {
  background: #ffffff4f;
  padding: 62px 32px;
  border-radius: 52px;
  text-align: center;
}

/* .powerfull-img {
  position: relative;
} */
.responsibility-section {
  background: linear-gradient(-45deg, #ffffff, #ffe4e9, #fcc8e2, #cec9ff, #655dd34f, #655dd34f, #8eb8ff, #91efff, #d5f9ff);
  background-size: 400% 400%;
  animation: gradient 9s ease infinite;
  padding: 130px 0;
  border-radius: 122px;
  margin: 0 52px;
}

.powerfull-img:after {
  content: "";
  position: absolute;
  width: 61%;
  height: 100%;
  top: -50px;
  right: 4px;
  background: #7269ef45;
  z-index: -1;
  border-radius: 42px 0 0 42px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.list-points {
  min-height: unset;
  margin-bottom: 26px;
  list-style: unset;
  margin-left: 21px;
}

.agrement-btn {
  display: inline-block;
  /* border: 1px solid #7269ee; */
  padding: 2px 14px;
  text-decoration: none;
  border-radius: 7px;
  background: #7269ef47;
  cursor: pointer;
  margin-top: 9px;
}

.agrement-btn a {
  color: #7168ed !important;
  text-decoration: none;
}

.agrement-btn a i {
  margin-left: 5px;
  margin-top: 0;
}

.click-me-btn,
.click-me-btn:hover {
  color: #7269ef;
  text-transform: capitalize;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.attachment-area.under-attachment-area {
  position: relative;
}

.attachment-area.under-attachment-area input {
  display: block;
  width: 20px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
  margin-right: 14px;
  margin-top: -18px;
}

.attachment-area.under-attachment-area img {
  position: absolute;
  pointer-events: none;
  top: -18px;
  cursor: pointer !important;
}
.swiper-3d .swiper-slide-shadow-left, .swiper-3d .swiper-slide-shadow-right{
  background-image: unset;
}
/* start responsive */
@media screen and (max-width: 1600px) {
  .hero-content h1 {
    font-size: 53px;
  }

  .powerfull-content h2,
  .subheading-text {
    font-size: 38px;
  }

  .hero-banner {
    margin: 0;
    height: unset;
  }

  .assests {
    left: 23%;
    width: 113px;
  }
}

@media screen and (max-width: 1300px) {
  .login-right {
    min-width: 440px;
  }

  .updated-content h1 {
    font-size: 51px;
  }

  .get-your-btn.btn.btn-primary,
  .get-your-btn.btn.btn-primary:focus-visible {
    padding: 17px 33px;
  }

  .responsibility-section {
    padding: 80px 0;
    border-radius: 0;
    margin: 0;
  }

  .google-login-btn button {
    width: 100%;
    padding: 10px 0px;
  }

  .google-login-btn {
    margin: 15px 10px 17px;
  }

  .awesome-inner-box {
    padding: 25px;
  }

  .feature-box {
    padding: 22px;
  }

  .feature-inner span {
    top: 71px;
  }

  .current-plan {
    top: -51px;
  }

  .card-header {
    padding: 15px;
  }

  .card .btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed {
    text-align: left;
  }
}

@media screen and (max-width: 1199px) {
  .hero-content h1 {
    font-size: 43px;
  }

  .feature-box,
  .feature-box.main-feature {
    padding: 30px 0;
  }

  .hero-content span {
    font-size: 48px;
  }

  .hero-banner {
    padding-bottom: 50px;
  }

  .feature-inner span {
    padding: 0;
  }

  .feature-box,
  .feature-box.main-feature {
    padding: 30px;
  }

  .card1 {
    width: 90%;
  }

  .payment-add-card h4 {
    font-size: 17px;
  }

  .chatbox-body {
    padding: 2px 22px;
    height: 462px;
  }

  .left-chat-box p {
    padding: 9px 10px;
    margin: 0 0 6px 0;
  }

  .chatbox-header {
    color: #000;
  }

  .left-chat-boxes {
    overflow: auto !important;
    min-height: 100% !important;
  }

  .left-chat-boxes,
  .right-chat-boxes {
    padding: 10px 11px 10px;
  }

  .awesome-inner-box {
    padding: 30px;
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .header .container {
    max-width: 98%;
  }

  .faq-img img {
    width: 91% !important;
  }

  .hero-banner .row {
    flex-direction: column-reverse;
  }
  .build-section .nav-link {
    margin-top: 0;
    margin-bottom: 15px;
}

.build-section .nav.nav-tabs {
  margin-top: 19px;
}
.build-section .accordion-button {
  padding: 26px 13px;
  border-radius: 15px !important;
  font-weight: 600;
  font-size: 15px;
}
.powerfull-content h2, .subheading-text {
  font-size: 29px;
  letter-spacing: 0;
}
.powerfull-content p, .list-points {
  font-size: 14px;
}
.margin-top60 {
  margin-top: 50px;
}
.powerfull-content p {
  margin-bottom: 11px;
}
.powerfull-content-under-img:after {
  height: 290px;
  border-radius: 22px;
}
.get-your-btn.btn.btn-primary, .get-your-btn.btn.btn-primary:focus-visible {
  padding: 12px 22px;
}
  .hero-content span {
    font-size: 40px;
  }

  .hero-content h1 {
    font-size: 35px;
  }

  .hero-img {
    text-align: center;
    margin-top: 40px;
  }

  .hero-content {
    margin-bottom: 20px;
  }

  .hero-img img {
    width: 500px;
    height: auto;
  }

  .features-section .row {
    justify-content: center;
  }

  .NFTICALLYAdvantageContent p {
    margin-bottom: 30px;
  }

  button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed,
  button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .capabilities-section h2:after {
    right: 15px;
  }

  .mid-chat-box {
    margin-top: 20px;
  }

  .call-to-action-right {
    justify-content: center;
    margin-top: 30px;
    text-align: center;
  }

  .cancel-area-box button {
    width: fit-content !important;
  }

  .privacy-box {
    text-align: center;
  }

  p.attorney-copyright {
    text-align: center;
    padding-bottom: 7px;
  }

  .payment-add-card h4 {
    font-size: 20px;
  }

  .payment-detail-area {
    height: unset;
  }

  .saved-cards-area {
    height: unset;
  }

  .assests {
    display: none;
  }

  .login-right {
    min-width: unset;
  }

  .login-right h6 {
    font-weight: 600;
  }

  .google-login-btn .btn-unset {
    margin: 0 !important;
  }

  .google-login-btn button {
    padding: 10px 40px;
  }
}

@media screen and (max-width: 767px) {
  .dropdown-top.mobile-tab {
    display: block;
  }
  .powerfull-content {
    margin-bottom: 52px;
}
.powerfull-section {
  padding: 82px 0 0 0;
}
  .header-right {
    display: none;
  }
  .faq-img {
    text-align: center;
}
.responsibilty-img {
  margin-bottom: 22px;
}
video.content-video {
  position: unset;
}
.talk-section {
  padding: 90px 0;
}
.powerfull-content {
  margin-top: 21px;
}
.privacy-content {
  font-size: 15px;
}
  .mobile-tab .dropdown-top .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-top .dropdown-toggle::after {
    display: none;
  }

  .mobile-tab .header-right {
    display: block;
  }

  .mobile-tab .header-top-right {
    margin: 12px 0;
  }

  .mobile-tab .activate-pro-faq {
    display: unset;
  }

  .dropdown-menu.show {
    right: 0px !important;
    padding: 15px;
  }

  .header-top-right:before {
    display: none;
  }

  .google-logo-style {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 575px) {
  .hero-banner video {
    height: 450px;
  }
  .updated-content h1 {
    font-size: 40px;
}
.updated-content h5 {
  font-size: 27px;
}
.sub-heading {
  font-size: 30px;
  margin-bottom: 15px;
}
.talk-section {
  padding: 67px 0;
}
.slider-blog-section {
  padding: 40px 0 42px 0;
}
  .cancel-btn.btn.btn-unset {
    border: none !important;
  }

  .privacy-content {
    margin: 90px 6px;
  }

  .modal-area-box .modal-dialog {
    margin: 0 auro;
  }

  /* .dropdown-top.mobile-tab {
    display: block;
  } */
  .terms-label label {
    float: unset;
  }

  .login-form-box {
    padding: 19px;
  }

  /* .cancel-btn.btn.btn-unset {
    width: 100% !important;
  } */
  .upgrade-to-pro-style {
    width: 100% !important;
  }

  .mobile-tab .header-right {
    display: block;
  }

  .feature-inner span {
    left: 0;
  }

  .mobile-tab .dropdown-top .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-top .dropdown-toggle::after {
    display: none;
  }

  .mobile-tab .dropdown-menu.show {
    max-width: 522px;
    right: 0 !important;
    padding: 15px;
  }

  .mobile-tab .header-top-right {
    margin: 12px 0;
  }

  .mobile-tab .dropdown-top button {
    background: transparent !important;
    font-size: 15px;
    font-weight: 600;
  }

  .mobile-tab .email-dropdown {
    display: unset;
    margin-left: 0;
  }

  .mobile-tab .activate-pro-faq {
    display: unset;
  }

  .mobile-tab .header-top-right:before {
    display: none;
  }

  /* .header-right {
    display: none;
  } */
  .hero-banner {
    border-radius: 0;
  }

  .current-plan {
    top: -20px;
    right: 10px;
  }

  .feature-box img {
    width: 30%;
  }

  .hero-content {
    padding: 0;
  }

  .hero-content h1 {
    font-size: 27px;
  }

  .hero-content span {
    font-size: 33px;
  }

  .NFTICALLYAdvantageContent .text-light {
    font-size: 45px;
  }

  .login-area .row {
    flex-direction: column-reverse;
  }

  .modal-dialog {
    width: 86%;
    margin: 0 auto;
  }

  .modal-title h5 {
    color: #000;
    font-weight: 600;
  }

  .login-left {
    display: none;
  }

  .on-boarding-login {
    height: 100vh;
    background: linear-gradient(239deg,
        rgba(255, 240, 160, 1) 0%,
        rgba(113, 104, 236, 1) 49%,
        rgba(59, 46, 228, 1) 100%);
  }

  .login-right {
    min-width: 80%;
    background: #fff;
  }

  .chatbox-header h3 {
    font-size: 20px;
    font-weight: 600;
  }

  .left-chat-box p {
    padding: 9px 15px;
    font-size: 14px;
    background: #e5e5e5;
  }

  .prompt:hover,
  .btn:focus-visible {
    font-size: 14px;
    font-weight: 600;
  }

  .modal-footer {
    display: none;
  }

  .prompt-box .dropdown-item p {
    background: #ededed;
    padding: 7px 8px;
    font-size: 12px;
    color: #000;
  }

  .prompt {
    font-size: 13px;
  }

  .mid-chat-box input,
  .mid-chat-box textarea {
    font-size: 13px;
  }

  .pricing-top-heading-area h4 {
    font-size: 35px;
  }
}

@media screen and (max-width: 480px) {
  .right-chat-box-dropdown .dropdown button {
    font-size: 13px;
  }

  .chat-history-left span {
    line-height: 16px;
  }

  .create-agreement-form-area .dropdown-menu.show {
    padding: 0;
  }

  .cancel-plan-box-area .modal-content {
    width: 100%;
  }

  .captcha-size {
    transform: scale(0.70);
    display: flex;
    justify-content: center;
  }

  .privacy-box {

    justify-content: center;
    flex-wrap: wrap;
  }

  .terms-condition-box-area h5 {
    font-size: 20px;
  }

  .terms-condition-box-area label.form-check-label {
    text-align: left;
  }

  .terms-condition-modal-area-area .modal-dialog {
    width: 90%;
  }
}

@media screen and (max-width: 420px) {
  .mid-chat-box form {

    margin: 46px 20px 12px;
    padding: 6px 0 6px 6px;

  }
  video.updated-video {
    height: 55vh;
}
.updated-content {
  left: 13px;
}
.get-your-btn.btn.btn-primary, .get-your-btn.btn.btn-primary:focus-visible {
  padding: 9px 17px;
}
.powerfull-img:after {
  width: 100%;
  right: 0;
}
.powerfull-section {
  padding: 42px 0 0 0;
}
.pricing-area {
  padding: 49px 0;
}
.build-section {
  padding: 32px 0;
}
.updated-content h5 {
  font-size: 19px;
}
.updated-content h1 {
  font-size: 24px;
}
.powerfull-content h2, .subheading-text {
  font-size: 25px;
}
video.content-video {
  position: relative;
  left: unset;
  top: 0;
  width: 100%;
}
.powerfull-content-under-img:after{
  display: none;
}
.uploaded-pdf-area.pdf-upload-text i {
  margin-left: 10px;
  cursor: pointer;
}
.uploaded-pdf-area.pdf-upload-text {
  background: #d8d5fb;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  font-size: 14px;
  width: fit-content;
  border-radius: 8px;
  margin: 8px;
  color: #7269ef;
  font-weight: 600;
  margin-bottom: 6px;
}
  .microphone-section-area {
    width: 52px;
  }

  .submit {
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    font-size: 16px;
  }

  .sub-para {
    line-height: 12px;
    padding: 0 25px 0;
  }

  .overlay-layer {

    height: 500px;
    min-height: 500px;
  }

  i.fa.fa-download {
    margin: 0 5px;
  }

  .prompt-library-dropdown-area .dropdown button {
    white-space: normal;
  }

  .chat-popup-area label.form-label {
    font-size: 14px;
  }

  .uplaod-document-area {
    margin-bottom: 12px;
  }

  .chat-agreement-area {
    margin: 6px 0 !important;
  }

  .modal-dialog .right-chat-spacing {
    padding: 0 10px 10px !important;
  }

  .right-chat-box-dropdown {
    margin: 0;
  }

  .modal-area-box .modal-header {
    padding: 0;
  }

  .btn-close:after {
    top: 8px;
  }

  .right-chat-box-dropdown br {
    display: none;
  }

  .cancel-area-box h5 {
    font-size: 18px !important;
  }

  .call-to-action-left h4 {
    font-size: 20px;
  }

  .call-to-action .container {
    padding: 25px;
  }

  .card button.btn.btn-link.text-dark.font-weight-bold.text-uppercase.collapsible-link.collapsed {
    font-size: 14px;
  }

  .login-form-box button {
    padding: 8px 9px;
  }

  .NFTICALLYAdvantageContent .text-light,
  .sub-heading {
    font-size: 30px;
  }

  .pricing-top-heading-area h4 {
    font-size: 30px;
    margin-bottom: 0;
  }

  .payment-add-card h4 {
    font-size: 17px;
  }

  .payment-detail-area {
    padding: 20px 20px;
  }

  .add-card button {
    background: #fff0a3 !important;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 8px;
    border: none !important;
    white-space: nowrap;
  }

  .login-right h6 {
    font-size: 20px;
  }

  .modal-dialog {
    width: 79%;
  }

  .right-chat-box-dropdown .dropdown button {
    font-size: 13px;
  }

  .modal-content {
    padding: 8px;
  }

  .mid-chat-box {
    background: #fff;
  }

  .inner-chatbot {
    background: #fff;
  }

  .google-login-btn button {
    font-size: 14px;
    border: 1px solid #000 !important;
  }

  .left-chat-boxes,
  .right-chat-boxes {
    background: #fff;
  }

  /* .inner-chatbot {
    padding: 7px 0;
  } */
  .add-chat-btn {
    margin: 12px 0;
  }

  .cancel-btn {
    width: 100% !important;
    padding: 8px !important;
    border: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  .google-login-btn button {
    padding: 10px 0;
  }

  .login-right {
    min-width: 100%;
  }
}